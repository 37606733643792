
import { Options, Vue } from "vue-property-decorator";
@Options({
  name: "Myproject",
})
export default class extends Vue {
  fGoProjectDetail(item: any) {
    this.$router.push({
      path: "/app/projectInfo",
      query: {
        id: item.projectId,
      },
    });
  }
}
