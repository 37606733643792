import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-442b0de6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "project-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_empty = _resolveComponent("van-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_nav_bar, {
      "left-arrow": "",
      title: _ctx.$route.meta.title,
      placeholder: "",
      onClickLeft: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$store.state.project.projectList.length > 0)
        ? (_openBlock(), _createBlock(_component_van_cell_group, { key: 0 }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.project.projectList, (item, index) => {
                return (_openBlock(), _createBlock(_component_van_cell, {
                  title: index + 1 + '. ' + item.projectName,
                  "is-link": "",
                  key: index,
                  onClick: ($event: any) => (_ctx.fGoProjectDetail(item))
                }, null, 8, ["title", "onClick"]))
              }), 128))
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_van_empty, {
            key: 1,
            description: "暂无项目"
          }))
    ])
  ]))
}